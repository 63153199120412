
import { defineComponent, ref, onMounted } from "vue"
import ApiService from "@/core/services/ApiService"
import { RoleUser } from "@/core/models/User"

export default defineComponent({
  name: "menu-user",
  components: {},
  setup() {
    const selectedUserId = ref(null)
    const roleUserList = ref<RoleUser[]>([])

    const menuList = ref([])
    const selectedMenuList = ref([])

    const businessDetail = ref([])
    const selectedBranch = ref(null)

    async function getMenuUser() {
      const { data } = await ApiService.get("menu/by-user/" + selectedUserId.value)
      menuList.value = data
      const list = []
      iterateMenuLists(data, list)
      selectedMenuList.value = list
    }

    async function getUsers() {
      const { data } = await ApiService.get("user")
      roleUserList.value = data
    }

    function iterateMenuLists(list, result) {
      list.forEach(item => {
        if (item.isSelected) {
          result.push(item.id)
        }
        iterateMenuLists(item.childMenuList, result)
      })
    }

    async function addUserMenu(menuId) {
      await ApiService.post("menu/add-user-menu", {
        menuId: menuId,
        userId: selectedUserId.value,
      })
      await getMenuUser()
    }

    function userMenuChange(data) {
      if (data.isSelected) {
        addUserMenu(data.id)
      } else {
        removeUserMenu(data.accountUserMenuId)
      }
    }

    async function removeUserMenu(id) {
      await ApiService.get("menu/remove-user-menu/" + id)
      await getMenuUser()
    }

    onMounted(async () => {
      await getUsers()
    })

    const defaultProps = {
      children: "childMenuList",
      label: "name",
      disabled: "hasChild",
    }

    const casProps = {
      children: "branches",
      label: "title",
      value: "id",
    }

    return {
      selectedUserId,
      defaultProps,
      userMenuChange,
      addUserMenu,
      removeUserMenu,
      getMenuUser,
      selectedMenuList,
      menuList,
      roleUserList,
      businessDetail,
      casProps,
      selectedBranch,
    }
  },
})
